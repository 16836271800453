import styled from "styled-components";

export const StyledArrowControlledInput = styled.div`
    display: flex;
    column-gap: 10px;
    align-items: center;
    align-self: start;
    justify-self: end;
`;

export const StyledArrowBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 6px;
`;

export const StyledButtonBlock = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 3px;

  ${({ $isDisplay }) =>
    $isDisplay === true
      ? `display: flex;`
      : `display: none;`}
`;

export const StyledButton = styled.button`
    margin: 0;
    border: none;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
    color: ${(props) => props.theme.colorWhite};
    font-size: 10px;
`;

export const StyledArrow = styled.button`
  width: 0;
  height: 0;
  border-left: 3px solid transparent;
  border-right: 3px solid transparent;
  border-bottom: none;
  background: none;
  padding: 0;
  cursor: pointer;

  ${({ direction }) =>
  direction === 'up'
    ? `border-bottom: 5px solid white;`
    : `border-top: 5px solid white;`}
  
  &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
      pointer-events: none;
  }

  &:hover {
    ${({ direction }) =>
      direction === 'up'
        ? `border-bottom-color: #a77ab3;`
        : `border-top-color: #a77ab3;`}
  }
`;

export const StyledNumber = styled.span`
  font-size: ${(props) => props.theme.fontSizeDefault};
  color: ${(props) => props.theme.colorWhite};
  line-height: 1.5rem;
`;

