import React, {useEffect, useState} from 'react';
import {StyledArrowControlledItem, StyledArrowControllerText} from './styles';
import ArrowControlledInput from '../arrow-controlled-input/arrow-controlled-input';

const ArrowControlledItem = ({
  id,
  name,
  cost,
  maxValue,
  disabled,
  isDisplayMinMax,
  onCountChange
}) => {
  const [count, setCount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [isMaxDisabled, setIsMaxDisabled] = useState(false);
  const priceText =  `${cost.toLocaleString('ru-RU')} руб.`
  const totalPriceText = `${totalPrice.toLocaleString('ru-RU')} руб.`

  useEffect(() => {
    if (disabled) { setIsMaxDisabled(true) } else { setIsMaxDisabled(false)}
  }, [disabled]);

  useEffect(() => {
    setTotalPrice(count * cost);
    onCountChange({id, count, totalPrice: count * cost});
    // eslint-disable-next-line
  }, [count, id]);

  const handleChangeCount = (value) => {
    setCount(value);
  }

  const handleMaxClick = () => {
    setCount(maxValue);
  }

  const handleMinClick = () => {
    setCount(0);
  }

  return (
    <StyledArrowControlledItem>
      <StyledArrowControllerText>{name}</StyledArrowControllerText>
      <ArrowControlledInput
        isDisplayMinMax={isDisplayMinMax}
        value={count}
        isMaxDisabled={isMaxDisabled}
        onChange={handleChangeCount}
        onMaxClick={handleMaxClick}
        onMinClick={handleMinClick}
      />
      <StyledArrowControllerText>{priceText}</StyledArrowControllerText>
      <StyledArrowControllerText>{totalPriceText}</StyledArrowControllerText>
    </StyledArrowControlledItem>
  )
}
export default ArrowControlledItem;
