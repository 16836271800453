import styled from "styled-components";
import {Li} from '../../styled';

export const StyledArrowControlledItem  = styled(Li)`
    display: grid;
    column-gap: 35px;
    grid-template-columns: 1fr 70px 85px 85px;
    z-index: 1;

    @media (max-width: 768px) {
      column-gap: 10px;
      grid-template-columns: 5fr 1fr 2fr 2fr;
    }
`;

export const StyledArrowControllerText = styled.span`
  font-size: ${(props) => props.theme.fontSizeDefault};
  color: ${(props) => props.theme.colorWhite};
  line-height: 1.5rem;
`;
