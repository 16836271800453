import React from "react";
import {StyledSummaryItem, StyledSummaryText} from './styles';

const SummaryItem = ({children, totalCount, totalPrice=0, isCount, isPrice}) => {
  const totalPriceText =  `${totalPrice.toLocaleString('ru-RU')} руб.`
  return (
    <StyledSummaryItem>
      <StyledSummaryText>{children}</StyledSummaryText>
      {isCount && <StyledSummaryText>{totalCount} шт.</StyledSummaryText>}
      {isPrice && <StyledSummaryText>{totalPriceText}</StyledSummaryText>}
    </StyledSummaryItem>
  )
}
export default SummaryItem;
