import styled from "styled-components";

const ArrowControlledList = styled.div`
  position: relative;
  &::after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    display: block;
    width: 40%;
    
    background-color: ${(props) => props.theme.colorBgList};
  }

    @media (max-width: 768px) {
      &::after {
        width: 100%; // или любое нужное значение
      }
    }
`;

export default ArrowControlledList;
