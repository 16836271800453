import { create } from 'zustand/react';
import { devtools } from 'zustand/middleware';

const useAppStore = create(
  devtools((set, get) => ({
    defaultOS: '',
    selectedPlatform: {},
    selectedOS: {id: ''},
    selectedModules: [],
    selectedSystems: [],

    setPakArticle: (article) => set(({ pakArticle: article }), false, 'pak/setPakArticle'),
    setPakPrice: (price) => set(({ pakPrice: price }), false, 'pak/setPakPrice'),
    setPakName: (name) => set(({ pakName: name }), false, 'pak/setPakName'),
    setDefaultOs: (osId) => set(({ defaultOS: osId }), false, 'pak/setDefaultOs'),

    setSelectedPlatform: (platform) => set(({selectedPlatform: platform}), false, 'pak/setSelectedPlatform'),
    setSelectedOs: (os) => set(({ selectedOS: os }), false, 'pak/setSelectedOs'),
    addModules: (modules) => set(({ selectedModules: modules }), false, 'pak/addModules'),
    addSystems: (systems) => set(({ selectedSystems: systems }), false, 'pak/addSystems'),
    reset: () => set((state) => ({
      selectedOS: state.defaultOS,
      selectedModules: [],
      selectedSystems: [],
    }), false, 'pak/reset'),

    isWindows: () => get().selectedOS.id.includes('win'),

    pakName: () => {
      const {selectedPlatform, selectedOS, selectedModules, selectedSystems} = get();
      const platformName = selectedPlatform.name;
      const osName = selectedOS.id;
      const moduleName = selectedModules.map((module) => module.id).join(', ');
      const systemName = selectedSystems.map((system) => system.id).join(', ');

      return `${platformName}. В составе: ${osName.toUpperCase()}, INS-00${systemName ? systemName.toUpperCase() : ''}${moduleName ? ', ' + moduleName.toUpperCase() : '' }`;
    },

    pakArticle: () => {
      const {selectedPlatform, selectedOS, selectedModules, selectedSystems} = get();
      const platformArticle = selectedPlatform.article;
      const osArticle = `ОС_${selectedOS.article}`;
      const moduleArticle = selectedModules.map((module) => module.article).join('_');
      const systemArticle = selectedSystems.map((system) => system.article).join('_');

      return `${platformArticle}(${osArticle}${systemArticle ? '/И' + systemArticle.slice(2) : ''}${moduleArticle ? '/М_' + moduleArticle : ''})`;
    },

    pakPrice: () => {
      const {selectedPlatform, selectedOS, selectedModules, selectedSystems} = get();
      const platformPrice = selectedPlatform.price;
      const osPrice = selectedOS.price;
      const modulePriceArray = selectedModules.map((module) => module.price);
      const modulePrice = modulePriceArray.reduce((partialSum, a) => partialSum + a, 0);
      const systemPriceArray = selectedSystems.map((system) => system.price);
      const systemPrice = systemPriceArray.reduce((partialSum, a) => partialSum + a, 0);

      return platformPrice + osPrice + modulePrice + systemPrice;
    },

    selectedPlatformId: () => get().selectedPlatform.id,
    selectedTypePlatform: () => get().selectedPlatform.type,
    selectedOsId: () => get().selectedOS.id,
    selectedModuleIds: () => get().selectedModules.map((module) => module.index),
    selectedSystemIds: () => get().selectedSystems.map((system) => system.index),
  }), {name: 'AppStore'})
);

export default useAppStore;