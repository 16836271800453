import React from "react";
import {StyledDeviceList} from "./styles";
import KgBlocks from '../../blocks/kg-blocks/kg-blocks';
import KgModules from '../../blocks/kg-modules/kg-modules';
import KgDoors from '../../blocks/kg-doors/kg-doors';
import KgSensors from '../../blocks/kg-sensors/kg-sensors';

const CalcFormKg = ({
  calcData: {kgBlocks = [], kgModules = [], kgDoors= [], kgSensors= []}
}) =>  {

  return (
    <StyledDeviceList >
      <KgBlocks options={kgBlocks} listName={'Выберите количество и тип блоков (до 20 на систему)'} />
      <KgModules options={kgModules} listName={'Выберите количество модулей (1 модуль на 8 пеналов)'}/>
      <KgDoors options={kgDoors} listName={'Выберите тип дверцы'}/>
      <KgSensors options={kgSensors} listName={'Выберите сенсорный дисплей'}/>
    </StyledDeviceList>
  );
}

export default CalcFormKg;
