import React, {useEffect} from 'react';
import RadioButtonList from '../../ui/radio-button-list/radio-button-list';
import Title, {TitleLevel} from '../../ui/title/title';
import {StyledArrowControlledList} from '../../styled';
import useKgStore from '../../../stores/use-kg-store';

const defaultSensor = 'kgrd-tft-pro-legic';

const KgSensors = ({options, listName}) => {
  const {addSensor, getSensorId} = useKgStore();
  const changeHandle = (event) => {
    addSensor(options.find((item) => item.id === event.target.value) || null)
  }

  useEffect(() => {
    addSensor(options.find((item) => item.id === defaultSensor) || null)
    // eslint-disable-next-line
  }, [options]);

  return (
    <StyledArrowControlledList>
      <Title level={TitleLevel.H2} className={"list__title"}>{listName}</Title>
      <RadioButtonList
        options={options}
        name={""}
        selectValue={getSensorId()}
        onChange={(event)=> changeHandle(event)}
      />
    </StyledArrowControlledList>

  )
}
export default KgSensors;
