import React from "react";
import CheckboxList from "../../ui/checkbox-list/checkbox-list";
import useAppStore from '../../../stores/use-app-store';

const Modules = ({
  modules,
  maxModules
}) => {
  const {selectedTypePlatform, selectedModuleIds, addModules } = useAppStore();

  const changeHandler = (value) => {
    const selectModules = value.map((id) => modules.find((item) => item.index === id));
    addModules(selectModules);
  }

  return (
    <CheckboxList
      listName={"Выберите программный модуль"}
      nameList="module"
      options={modules}
      selectValues={selectedModuleIds()}
      onChange={changeHandler}
      maxModules={maxModules}
      uncheck={selectedTypePlatform() === 3}
    />
  );
}

export default Modules;
