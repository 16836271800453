import React, {useEffect, useState} from 'react';
import ArrowControlledItem from '../arrow-controlled-item/arrow-controlled-item';
import {StyledUl} from './styles';

const ArrowControlledList = ({ maxValue, options, selectedItems, disabled, isDisplayMinMax}) => {
  const [selected, setSelected] = useState([]);

  const handleChangeCount = ({ id, count, totalPrice }) => {
    setSelected((prev) => {
      if (count <= 0) {
        // Удаляем элемент с этим id
        return prev.filter((item) => item.id !== id);
      }

      const original = options.find((opt) => opt.id === id);
      if (!original) return prev; // защита на случай, если item не найден

      const newItem = { ...original, count, totalPrice };

      const exists = prev.find((item) => item.id === id);

      if (exists) {
        // Обновляем данные
        return prev.map((item) => item.id === id ? newItem : item);
      } else {
        // Добавляем новый элемент
        return [...prev, newItem];
      }
    });
  };


  useEffect(() => {
    selectedItems(selected)
    // eslint-disable-next-line
  }, [selected]);

  return (
    <StyledUl>
      {options.map((item) => {
        return (
          <ArrowControlledItem
            key={item.index}
            isDisplayMinMax={isDisplayMinMax}
            maxValue={maxValue}
            id={item.id}
            cost={item.price}
            name={item.name}
            onCountChange={handleChangeCount}
            disabled={disabled}
          />
        );
      })}
    </StyledUl>
  )
}
export default ArrowControlledList;
