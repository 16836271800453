import styled from "styled-components";

export const StyledSummaryItem  = styled.div`
    display: grid;
    column-gap: 35px;
    grid-template-columns: 1fr 85px 85px;
    z-index: 1;
    padding-left: 20px;
    margin-top: 15px;
    
`;

export const StyledSummaryText = styled.span`
  font-size: ${(props) => props.theme.fontSizeDefault};
  color: ${(props) => props.theme.colorWhite};
  line-height: 1.5rem;
`;
