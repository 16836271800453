import { create } from 'zustand/react';
import { devtools } from 'zustand/middleware';

const useKgStore = create(
  devtools((set, get) => ({
    selectedBlocks: [],
    selectedModules: [],
    selectedDoor: {},
    selectedSensor: {},

    addBlocks: (blocks) => set(({selectedBlocks: blocks}), false, 'kg/addBlocks'),
    addModules: (modules) => set(({selectedModules: modules}), false, 'kg/addModules'),
    addDoor: (door) => set(({selectedDoor: door}), false, 'kg/addDoor'),
    addSensor: (sensor) => set(({selectedSensor: sensor}), false, 'kg/addSensor'),

    // 👇 Добавляем геттеры
    getTotalBlockCount: () => {
      return get().selectedBlocks.reduce((sum, item) => sum + (item.count || 0), 0);
    },
    getTotalBlockPrice: () => {
      return get().selectedBlocks.reduce((sum, item) => sum + (item.totalPrice || 0), 0);
    },
    getTotalModuleCount: () => {
      return get().selectedModules.reduce((sum, item) => sum + (item.count || 0), 0);
    },
    getTotalModulePrice: () => {
      return get().selectedModules.reduce((sum, item) => sum + (item.totalPrice || 0), 0);
    },
    getMaxModulesCount: () => {
      return get().selectedBlocks.reduce((sum, item) => {
        const count = item.count || 0;
        const maxModules = item.maxModules || 0;
        return sum + count * maxModules;
      }, 0);
    },
    getKeysCount: () => {
      return get().selectedModules.reduce((sum, item) => {
        const count = item.count || 0;
        return sum + count * item.keysCount || 0;
      }, 0);
    },
    getDoorId: () => {
      return get().selectedDoor.id;
    },
    getTotalDoorPrice: () => {
      const {selectedDoor, getTotalBlockCount} = get();
      return selectedDoor.price * getTotalBlockCount();
    },
    getSensorId: () => {
      return get().selectedSensor.id;
    },
    getTotalSensorPrice: () => {
      const {selectedSensor} = get();
      return selectedSensor.price;
    },

    sysName: () => {
      const { selectedBlocks, selectedModules, selectedDoor, selectedSensor, getKeysCount, getTotalBlockCount } = get();
      const systemName = `Система хранения ключей КиГард на ${getKeysCount() || 0} пеналов`;

      const parts = [];

      const blockName = selectedBlocks
        .slice()
        .sort((a, b) => a.index - b.index)
        .map((block) => `- ${block.name} - ${block.count} шт.`)
        .join('\n');
      if (blockName) parts.push(blockName);

      const moduleName = selectedModules
        .map((module) => `- ${module.name} - ${module.count} шт.`)
        .join('\n');
      if (moduleName) parts.push(moduleName);

      if (selectedDoor?.name) {
        parts.push(`- ${selectedDoor.name} - ${getTotalBlockCount()} шт.`);
      }

      if (selectedSensor?.name) {
        parts.push(`- ${selectedSensor.name} - 1 шт.`);
      }

      return `${systemName}.\nВ составе:\n${parts.join('\n')}`;
    },

    // KGRD-PRO-Legic (2303/2502/2101/8001/216)
    article: () => {
      const { selectedBlocks, selectedModules, selectedDoor, getKeysCount } = get();

      const formatArticle = (article, count) => {
        const paddedCount = count < 10 ? `0${count}` : `${count}`;
        const str = article.toString();
        if (!str.endsWith('00')) return str;
        return str.slice(0, -2) + paddedCount;
      };

      const parts = [];

      const blockArticle = selectedBlocks
        .slice()
        .sort((a, b) => a.index - b.index)
        .map((block) => formatArticle(block.article, block.count))
        .join(' / ');
      if (blockArticle) parts.push(blockArticle);

      const modulesArticle = selectedModules
        .slice()
        .sort((a, b) => a.index - b.index)
        .map((module) => formatArticle(module.article, module.count))
        .join(' / ');
      if (modulesArticle) parts.push(modulesArticle);

      if (selectedDoor.article) {
        parts.push(selectedDoor.article);
      }

      return `KGRD-PRO-Legic-${getKeysCount()} (${parts.join(' / ')})`;
    },

    sysPrice: () => {
      const {getTotalBlockPrice, getTotalModulePrice, getTotalDoorPrice, getTotalSensorPrice} = get();
      return getTotalBlockPrice() + getTotalModulePrice() + getTotalDoorPrice() + getTotalSensorPrice();
    }
  }))
)

export default useKgStore;
