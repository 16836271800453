import React from "react";
import CheckboxList from "../../ui/checkbox-list/checkbox-list";
import useAppStore from '../../../stores/use-app-store';

const Systems = ({
  modules,
  maxModules
}) => {
    const {selectedTypePlatform, selectedSystemIds, addSystems } = useAppStore();

    const changeHandler = (value) => {
    const selectModules = value.map((id) => modules.find((item) => item.index === id));
    addSystems(selectModules);
  }

  return (
    <>
      <CheckboxList
        listName={"Выберите подключаемую систему (до 3-х систем)"}
        nameList="system"
        options={modules}
        selectValues={selectedSystemIds()}
        onChange={changeHandler}
        maxModules={maxModules}
        uncheck={selectedTypePlatform() === 4}
        desc={'*Стоимость первого модуля составляет 700 000 руб.'}
      />
    </>
  );
}

export default Systems;
