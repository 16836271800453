import React, {useEffect} from "react";
import RadioButtonList from "../../ui/radio-button-list/radio-button-list";
import useAppStore from '../../../stores/use-app-store';

const Platform = ({
  platform
}) => {
  const {selectedPlatformId, setSelectedPlatform, reset } = useAppStore();

  useEffect(() => {
    setSelectedPlatform(platform.find((item) => item.id === platform[0].id))
    // eslint-disable-next-line
  }, []);

  const changeHandle = (evt) => {
    const selectPlatform =  platform.find((item) => item.id === evt.target.value);
    setSelectedPlatform(selectPlatform)
    reset();
  }

  return (
    <RadioButtonList
      listName={"Выберите аппаратную платформу"}
      options={platform}
      name={"platform"}
      selectValue={selectedPlatformId()}
      onChange={changeHandle}
    />
  )
}

export default Platform;
