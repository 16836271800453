import React from 'react';
import {
  StyledArrow,
  StyledArrowBlock,
  StyledArrowControlledInput,
  StyledButton,
  StyledButtonBlock,
  StyledNumber
} from './styles';

const ArrowControlledInput = ({isDisplayMinMax, value, isMaxDisabled, onChange, onMaxClick, onMinClick}) => {
  const incrementCount = () => {
    onChange((prev) => (prev + 1))
  }

  const decrementCount = () => {
    onChange((prev) => (Math.max(0, prev - 1)))
  }

  return (
    <StyledArrowControlledInput>
      <StyledButtonBlock $isDisplay={isDisplayMinMax}>
        <StyledButton onClick={() => onMaxClick()}>макс</StyledButton>
        <StyledButton onClick={() => onMinClick()}>сброс</StyledButton>
      </StyledButtonBlock>
      <StyledArrowBlock>
        <StyledArrow
          direction="up"
          disabled={isMaxDisabled}
          onClick={incrementCount}
        />
        <StyledArrow
          direction="down"
          disabled={value <= 0}
          onClick={decrementCount}
        />
      </StyledArrowBlock>
      <StyledNumber>{value}</StyledNumber>
    </StyledArrowControlledInput>
  )
}
export default ArrowControlledInput;
