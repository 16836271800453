import React from "react";
import {
  StyledProductCard,
  ProductOptionList,
  StyledTerm,
  StyledDefinition,
} from "./styles";
import Title, { TitleLevel } from "../../ui/title/title";
import Button from "../../ui/button/button";
import useKgStore from "../../../stores/use-kg-store";
import { generateOfferPDF } from "../../../utils/pdf-generator";


const KgProductCard = () => {
  const {
    sysName,
    article,
    sysPrice,
    selectedBlocks,
    selectedModules,
    selectedDoor,
    selectedSensor,
    getKeysCount,
  } = useKgStore();

  const handleGeneratePDF = () => {
    const equipment = [
      ...selectedBlocks.map((block) => ({
        name: block.name,
        article: block.article.replace(
          "00",
          block.count < 10 ? `0${block.count}` : `${block.count}`
        ),
        count: block.count,
        price: block.totalPrice,
      })),
      ...selectedModules.map((mod) => ({
        name: mod.name,
        article: mod.article,
        count: mod.count,
        price: mod.totalPrice,
      })),
      {
        name: selectedDoor.name,
        article: selectedDoor.article,
        count: selectedBlocks.reduce((sum, b) => sum + b.count, 0),
        price:
          selectedDoor.price *
          selectedBlocks.reduce((sum, b) => sum + b.count, 0),
      },
      {
        name: selectedSensor.name,
        article: selectedSensor.article,
        count: 1,
        price: selectedSensor.price,
      },
    ];

    generateOfferPDF({
      equipment,
      totalPrice: sysPrice(),
      article: article(),
    });
  };

  return (
    <StyledProductCard>
      <Title
        level={TitleLevel.H2}
        className={"list__title list__title--product"}
      >
        Система хранения ключей КиГард:
      </Title>
      <ProductOptionList>
        <StyledTerm>Наименование:</StyledTerm>
        <StyledDefinition
          dangerouslySetInnerHTML={{
            __html: sysName().replace(/\n/g, "<br />"),
          }}
        />

        <StyledTerm>Артикул:</StyledTerm>
        <StyledDefinition>{article()}</StyledDefinition>

        <StyledTerm>Стоимость:</StyledTerm>
        <StyledDefinition>
          {sysPrice().toLocaleString("ru-RU") || "-"} руб.
        </StyledDefinition>

        <Button fullWidth disabled={getKeysCount() === 0} as="button" onClick={handleGeneratePDF}>
          Сформировать КП
        </Button>
      </ProductOptionList>
    </StyledProductCard>
  );
};

export default KgProductCard;
