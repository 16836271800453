import React from "react";
import Title, {TitleLevel, TitleSize} from "../components/ui/title/title";
import {MainWrapper} from "../components/layout/page-wrapper/styles";
import {Container} from "../components/styled";
import CalcFormKg from '../components/layout/calc-form-kg/calc-form-kg';
import KgProductCard from '../components/blocks/kg-product-card/kg-product-card';

const PageKgrd = ({calcData}) => {
  return (
    <Container>
      <Title className={"header"} level={TitleLevel.H1} size={TitleSize.BIG}>Подберите конфигурацию<br/><span>Системы хранения ключей КиГард</span></Title>
      <MainWrapper>
        <CalcFormKg calcData={calcData}/>
        <KgProductCard />
      </MainWrapper>
    </Container>
  )
}

export default PageKgrd;
