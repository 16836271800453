import React from 'react';
import Title, {TitleLevel} from '../../ui/title/title';
import SummaryItem from '../../ui/summary-item/summary-item';
import ArrowControlledList from '../../ui/arrow-controlled-list/arrow-controlled-list';
import useKgStore from '../../../stores/use-kg-store';
import {StyledArrowControlledList} from '../../styled';

const KgModules = ({options, listName}) => {
  const { addModules, getTotalModuleCount, getTotalModulePrice, getTotalBlockPrice, getMaxModulesCount, getKeysCount } = useKgStore();

  const handleSelectedItems = (selectedItems) => {
    addModules(selectedItems)
  }

  const handleMaxClick = () => {
    console.log('handleMaxClick');
  }

  return (
    <StyledArrowControlledList>
      <Title level={TitleLevel.H2} className={"list__title"}>{listName}</Title>
      <SummaryItem isCount totalCount={getMaxModulesCount()} totalPrice={getTotalBlockPrice()}>Максимальное количество модулей:</SummaryItem>
      <SummaryItem isCount totalCount={getKeysCount()} totalPrice={getTotalModulePrice()}>Количество ключей в системе:</SummaryItem>
      <ArrowControlledList isDisplayMinMax maxValue={getMaxModulesCount()} onClick={handleMaxClick} disabled={getTotalModuleCount() >= getMaxModulesCount()} options={options} selectedItems={handleSelectedItems}/>
    </StyledArrowControlledList>
  )
}
export default KgModules;
