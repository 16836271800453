import React, {useEffect} from 'react';
import RadioButtonList from '../../ui/radio-button-list/radio-button-list';
import Title, {TitleLevel} from '../../ui/title/title';
import {StyledArrowControlledList} from '../../styled';
import useKgStore from '../../../stores/use-kg-store';
import SummaryItem from '../../ui/summary-item/summary-item';

const defaultSensor = 'kgrd-3001';

const KgDoors = ({options, listName}) => {
  const {getTotalBlockCount, addDoor, getDoorId} = useKgStore();

  const changeHandle = (event) => {
    addDoor(options.find((item) => item.id === event.target.value) || null);
  }

  useEffect(() => {
    addDoor(options.find((item) => item.id === defaultSensor) || null)
    // eslint-disable-next-line
  }, [options]);

  return (
    <StyledArrowControlledList>
      <Title level={TitleLevel.H2} className={"list__title"}>{listName}</Title>
      <SummaryItem isCount totalCount={getTotalBlockCount()}>Количество дверц (по количеству блоков):</SummaryItem>
      <RadioButtonList
        options={options}
        name={""}
        selectValue={getDoorId()}
        onChange={(event)=> changeHandle(event)}
      />
    </StyledArrowControlledList>

  )
}
export default KgDoors;
