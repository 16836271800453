import styled from "styled-components";

export const StyledButton = styled.a`
  display: block;
  min-height: 44px;
  font-size: ${(props) => props.theme.fontSizeDefault};
  min-width: ${(props) => `${props.$minWidth || 100}px`};
  font-weight: 700;
  line-height: 1.5rem;
  color: ${(props) => props.theme.colorWhite};
  background-color: ${(props) => props.theme.colorForButton};
  text-align: center;
  border-radius: 5px;
  text-decoration: none;
  border: none;
  background-image: none;
  box-shadow: none;
  cursor: pointer;
  transition: background-color 0.2s ease-out, box-shadow 0.2s ease-out;

  ${(props) => props.$fullWidth && 'grid-column: 1 / -1;'}

  ${(props) =>
          props.disabled &&
          `
    opacity: 0.5;
    cursor: not-allowed;
    pointer-events: none;
  `}

  &:hover,
  &:active {
    background-color: ${(props) => props.theme.colorForButtonHover};
    box-shadow: inset 0 4px 0 rgba(0, 0, 0, 0.14);
  }

  &:active {
    box-shadow: none;
  }
`;
