import React from "react";
import {StyledProductCard, ProductOptionList, StyledTerm ,StyledDefinition} from "./styles";
import Title, {TitleLevel} from "../../ui/title/title";
import Button from "../../ui/button/button";
import {observer} from "mobx-react-lite";
import useAppStore from '../../../stores/use-app-store';

const ProductCard = () => {
  const {pakArticle, isWindows, pakName, pakPrice } = useAppStore();
  const isShowButton = false;

  return (
    <StyledProductCard>
      <Title level={TitleLevel.H2} className={'list__title list__title--product'}>Программно-аппаратный комплекс (ПАК) ИНСОНЕТ:</Title>
      <ProductOptionList>
        <StyledTerm>Наименование:</StyledTerm>
        <StyledDefinition>{pakName()}</StyledDefinition>
        <StyledTerm>Артикул:</StyledTerm>
        <StyledDefinition>{pakArticle()}</StyledDefinition>
        <StyledTerm>Стоимость:</StyledTerm>
        <StyledDefinition>{pakPrice().toLocaleString('ru-RU') || '-'} руб.</StyledDefinition>
        {isWindows() && <StyledTerm className="attention">Внимание</StyledTerm>}
        {isWindows() && <StyledDefinition className="attention">Необходимо уточнить цену на windows</StyledDefinition>}
        {isShowButton && <Button as="button" >Подробнее</Button>}
      </ProductOptionList>
    </StyledProductCard>
  )
}
export default observer (ProductCard);
