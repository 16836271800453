import React from 'react';
import ArrowControlledList from '../../ui/arrow-controlled-list/arrow-controlled-list';
import Title, {TitleLevel} from '../../ui/title/title';
import SummaryItem from '../../ui/summary-item/summary-item';
import useKgStore from '../../../stores/use-kg-store';
import {StyledArrowControlledList} from '../../styled';

const KgBlocks = ({options, listName}) => {
  const { addBlocks, getTotalBlockCount, getTotalBlockPrice } = useKgStore();

  const handleSelectedItems = (selectedItems) => {
    addBlocks(selectedItems);
  }

  return (
    <StyledArrowControlledList>
      <Title level={TitleLevel.H2} className={"list__title"}>{listName}</Title>
      <SummaryItem isPrice isCount totalCount={getTotalBlockCount()} totalPrice={getTotalBlockPrice()}>Итого:</SummaryItem>
      <ArrowControlledList disabled={getTotalBlockCount() >= 20} options={options} selectedItems={handleSelectedItems}/>
    </StyledArrowControlledList>
  )
}
export default KgBlocks;
