import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { font } from '../fonts/roboto-normal.js';
import logo from '../assets/logo.png';
import kgrd from '../assets/kgrd.png';


export const generateOfferPDF = ({ equipment = [], totalPrice, article='' }) => {
  const doc = new jsPDF();

  // Заголовок
  doc.addFileToVFS("Roboto-Regular.ttf", font);
  doc.addFont("Roboto-Regular.ttf", "Roboto", "normal");
  doc.setFont("Roboto");

  // === Хелпер для вывода текста с auto-Y ===
  let lineHeight = 7;
  let currentY = 20;
  const paddingLeft = 15;

  const checkPageBreak = (nextLineHeight = lineHeight) => {
    const pageHeight = doc.internal.pageSize.height;
    if (currentY + nextLineHeight > pageHeight - 20) {
      doc.addPage();
      currentY = 20; // сбрасываем Y на начало новой страницы
    }
  };

  const printLine = (text, opts = {}) => {
    const x = opts.x || paddingLeft;
    const options = opts.options || {};
    const maxWidth = opts.maxWidth || 170;

    const lines = doc.splitTextToSize(text, maxWidth);
    const neededHeight = lines.length * lineHeight;

    if (opts.y === undefined) {
      checkPageBreak(neededHeight); // ⬅️ проверка перед выводом
    }

    doc.text(lines, x, currentY, options);

    if (opts.y === undefined) {
      currentY += neededHeight;
    }
  };

  doc.setFontSize(8);
  printLine('129343, Город Москва, вн.тер. г. Муниципальный Округ Свиблово, ул Уржумская, дом 4, строение 2', {maxWidth: 70})

  doc.setFontSize(10);
  lineHeight = 5;
  printLine('Tel/fax. +7 (495) 225-23-89')
  printLine('e-mail: a.kozlova@quick-set.ru')
  printLine('https://quick-set.ru/')

  doc.setFontSize(16);
  currentY = 80;
  lineHeight = 7;
  printLine('Коммерческое предложение', {x: 100, options: { align: 'center' }})
  printLine('на систему хранения ключей PRO-Legic', {x: 100, options: { align: 'center' }})
  printLine('');
  doc.setFontSize(14);
  printLine(`Артикул: ${article}`, {x: 100, options: { align: 'center' }})
  printLine('');

  // 📦 Таблица с оборудованием
  const tableBody = equipment.map((item, index) => ([
    index + 1,
    item.name,
    `${item.count} шт.`,
    `${item.price.toLocaleString('ru-RU')} ₽`
  ]));

  let finalY = 0;

  autoTable(doc, {
    startY: 110,
    head: [['№ п/п', 'Комплектация', 'Кол-во', 'Цена']],
    body: tableBody,
    styles: {
      fontSize: 10,
      font: 'Roboto',
    },
    headStyles: {
      fillColor: [41, 128, 185],
      font: 'Roboto',
      fontStyle: 'normal', // ✅ важно!
      textColor: 255,
    },
    columnStyles: {
      3: { cellWidth: 'auto', halign: 'right' }, // 👈 автоширина + выравнивание по правому краю
    },
    didParseCell: function (data) {
      if (data.section === 'head') {
        data.cell.styles.font = 'Roboto'; // 👈 ручная установка
        data.cell.styles.fontStyle = 'normal';
      }
    },
    didDrawPage: (data) => {
      doc.addImage(logo, 'PNG', paddingLeft, 1, 50, 15);
      doc.addImage(kgrd, 'PNG', 150, 1, 50, 60)
    },

    // 👇 финальный Y получим после полной отрисовки таблицы
    didDrawCell: (data) => {
      if (data.row.index === tableBody.length - 1 && data.section === 'body') {
        finalY = data.cell.y + data.cell.height + 10;
      }
    },
  });

  currentY = finalY;
  // 💰 Итоговая цена
  printLine(`Итого: ${totalPrice.toLocaleString('ru-RU')} ₽`, {x:150})

  // 🧾 Примечание
  doc.setFontSize(10);
  currentY = finalY + 20;
  lineHeight = 7;
  printLine('Гарантия 5 лет. Срок поставки 2 недели.')
  printLine('Цена с учетом НДС.')
  printLine('')

  lineHeight = 5;
  doc.setFontSize(12);
  printLine('Возможности системы:')
  doc.setFontSize(10);
  printLine('• Безопасное хранение ключей,')
  printLine('• Автоматизация процесса выдачи и сдачи ключей - экономия времени сотрудников,')
  printLine('• Исключение человеческого фактора и контроль персонала, ')
  printLine('• Настройки индивидуальных прав доступа пользователей к ключам и спискам ключей,')
  printLine('• Настройки временных зон доступа включая индивидуальные списки дней, выходные, праздн.')
  printLine('• Просмотр истории событий по любому ключу непосредственно с панели  системы хранения, включая ФИО сотрудника и телефон (доступно 16 последних событий - по умолчанию),')
  printLine('• Поиск ключа по названию или по номеру на панели ключницы,')
  printLine('• Функция фильтра сданных ключей,')
  printLine('• Функции записи причины выдачи и отчета по сдаче ключа. Данные функции удобны для выдачи аварийных ключей. Можно указать, непосредственно с панели ключницы, причину выдачи и результат проделанной работы. Эти данные (текст) будет доступен в отчетах системы. ')
  printLine('• Поиск ключа на объекте (если используется распределенная система хранения),')
  printLine('• Функция экстренной выдачи ключей,')
  printLine('• Функция немедленной выдачи - сдачи ключа (не требуются никакие дополнительные нажатия клавиш или выбор режима). Достаточно только авторизоваться картой и Ваш ключ будет немедленно выдан. ')
  printLine('• Функция выдачи команды на установку и снятия помещений с охраны с отображением отчета результата события. В случае неготовности отображается название и номер группы и рубежа охраны. (доступно только при интеграции с охранной сигнализацией),')
  printLine('• Формирование всевозможных отчетов при помощи программного обеспечения,')
  printLine('• Графическое представление объекта на планах с указанием сданных и выданных ключей, так же можно указать и состояние зон охраны и охранных датчиков объекта,')
  printLine('• Фильтр (отчет) не сданных ключей, ')
  printLine('• Формирование тревоги не сдачи ключа до или в течении определенного времени,')
  printLine('• Неограниченное подключение систем и рабочих станций,')
  printLine('• SMS и Email информирование тревог и событий (просроченных ключей и выданных т.д.),')
  printLine('• Макрокоманды - программирование зависимостей, событий и т.д.,')
  printLine('• Протоколирование всех действий системы,')
  printLine('• Расчет рабочего времени сотрудников,')
  printLine('• Возможность импорта и экспорта базы данных пользователей и карт доступа,')
  printLine('• Протоколы связи с охранной сигнализацией и системой автоматики здания. ')
  printLine('')
  printLine('')
  doc.setFontSize(12);
  printLine('Краткое описание работы системы:')
  doc.setFontSize(10);
  printLine('1. Для получения ключей: Поднесите карточку или введите код Система разблокирует дверь и подсветит ячейку с данным ключом и разблокирует ее. ')
  printLine('Если Вы имеете право взять несколько ключей появится дополнительное меню. В данном меню вы сможете выбрать из своего списка один или несколько ключей, воспользоваться поиском, фильтром, или использовать режим выдачи группы ключей. ')
  printLine('Так же здесь доступна клавиша История (последние события по ключу). После выбора ключа или группы ключей нажмите клавишу Выдать - система разблокирует дверцу и выдаст вам ключи один за одним. ')
  printLine('2. Для Сдачи ключей. Поднесите карточку к левому считывателю. Вставьте ключи один за одним. ')
  printLine('3. При интеграции с охранной сигнализацией при сдачи или выдачи ключей на дисплее ключницы так же отобразится результат установки или снятия помещений с охраны. ')
  printLine('В случае установки помещения на охрану, если зона не готова - система отобразит номер и название группы и не готового датчика. Вы сможете забрать ключ и пойти проверить охранную сигнализацию.')
  printLine('4. Вся история событий кто взял или сдал ключ доступна как непосредственно с дисплея ключницы так и с прилагающегося программного обеспечения. ')

  // Сохранение
  doc.save('Коммерческое_предложение.pdf');
};
