import React, {useEffect} from "react";
import RadioButtonList from "../../ui/radio-button-list/radio-button-list";
import useAppStore from '../../../stores/use-app-store';

const Os = ({
  os
}) => {
  const { selectedOsId, setSelectedOs, setDefaultOs } = useAppStore();

  useEffect(() => {
    const defaultOs = os.find((item) => item.id === os[0].id)
    setSelectedOs(defaultOs);
    setDefaultOs(defaultOs);
    // eslint-disable-next-line
  }, []);

  const changeHandle = (evt) => {
    const selectOs =  os.find((item) => item.id === evt.target.value);
    setSelectedOs(selectOs);
  }

  return (
    <RadioButtonList
      listName={"Выберите операционную систему"}
      options={os}
      name={"os"}
      selectValue={selectedOsId()}
      onChange={changeHandle}
    />
  )
}

export default Os;
